import {useCallback, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import moment from "moment";
import './GitHubActivity.scss';

function GitHubActivity(props) {
    const [events, setEvents] = useState([]);

    const getBranchName = (event) => {
        return event.payload.ref ? event.payload.ref.split('/').pop() : null
    }

    const filterEvents = useCallback((responseEvents) => {
        // todo migrate all filterings from renderings to here for better control
        const ignoringIssueNumbers = [6489]
        const ignoringEvents = ['ForkEvent', 'PublicEvent', 'DeleteEvent'];
        const isOwnRepository = (event) => {
            return event.repo.name.split('/')[0] === props.username
        }
        const isBranch = (event, branch) => {
            return getBranchName(event) === branch
        }
        const isSameEvent = (event1, event2) => {
            const isSameType = event1.type === event2.type
            const isSameBranch = getBranchName(event1) === getBranchName(event2)
            return isSameType && isSameBranch
        }
        const shouldShowPushEvent = (event, nextEvent) => {
            if (nextEvent && isSameEvent(nextEvent, event)) {
                return false
            }
            return isOwnRepository(event) && isBranch(event, 'master')
        }
        return responseEvents.filter((event, index) => {
            if (event.payload.issue && ignoringIssueNumbers.includes(event.payload.issue.number)) {
                return false
            }
            if (ignoringEvents.includes(event.type)) {
                return false
            }
            if (event.type === 'PushEvent') {
                const nextEvent = responseEvents[index + 1]
                return shouldShowPushEvent(event, nextEvent)
            }
            return true
        });
    }, [props.username])

    useEffect(() => {
        fetch(`https://api.github.com/users/${props.username}/events`)
            .then(res => res.json())
            .then(
                (response) => {
                    const events = response;
                    const filteredEvents = filterEvents(events)
                    setEvents(filteredEvents)
                },
                (response) => {
                    console.log(response)
                }
            )
    }, [props.username, filterEvents])

    const renderExternalLink = (url, label) => {
        return <a href={url} target={'_blank'} rel={'noreferrer'}>{label}</a>
    }
    const renderAuthorLink = () => {
        if (!events.length) {
            return;
        }
        return renderExternalLink(`https://github.com/${props.username}`, events[0].actor.display_login);
    }
    const renderAuthor = () => {
        if (!events.length) {
            return;
        }
        return <>
            <img src={events[0].actor.avatar_url} alt={''}/> {renderAuthorLink()}
        </>
    }
    const renderPullRequestEventAction = (event) => {
        const link = renderExternalLink(
            event.payload.pull_request.html_url,
            `${event.repo.name}#${event.payload.number}`
        );
        return <>
            {event.payload.action} pull request {link}<br/>
            <span className={'message'}>{event.payload.pull_request.title}</span>
        </>;
    }
    const createIssueName = (event) => {
        return `${event.repo.name} - ${event.payload.issue.title}`;
    }
    const renderIssueCommentEventAction = (event) => {
        const link = renderExternalLink(event.payload.issue.html_url, createIssueName(event));
        const messageLengthLimit = 70;
        const message = event.payload.comment.body.length > messageLengthLimit ?
            event.payload.comment.body.substring(0, messageLengthLimit) + '...' :
            event.payload.comment.body;
        return <>
            commented issue {link}<br/>
            <span className={'message'}>{convertGitHubUrlsToReferences(message)}</span>
        </>
    }

    const convertGitHubUrlsToReferences = (string) => {
        const pattern = /https:\/\/github\.com\/.+?\/issues\/(\d+)/g
        const replace = (match, issueNumber) => `#${issueNumber}`
        return string.replace(pattern, replace)
    }

    const renderCreateEventAction = (event) => {
        if (event.payload.ref_type === 'tag') {
            return;
        }
        const branchName = event.repo.name.split('/')[1];
        if (`${props.username}/${branchName}` === event.repo.name) {
            return;
        }
        const link = renderExternalLink(`https://github.com/${event.repo.name}`, branchName);
        return <>created {link}</>
    }
    const renderIssuesEventAction = (event) => {
        const link = renderExternalLink(
            `https://github.com/${event.repo.name}/issues/${event.payload.issue.number}`,
            createIssueName(event)
        );
        return <>{event.payload.action} issue {link}</>
    }
    const renderWatchEvent = (event) => {
        return <>starred {renderExternalLink(`https://github.com/${event.repo.name}`, event.repo.name)}</>
    }
    const renderPushEvent = (event) => {
        return <>pushed {getBranchName(event)} on {renderExternalLink(`https://github.com/${event.repo.name}`, event.repo.name)}</>
    }
    const renderEventAction = (event) => {
        switch (event.type) {
            case 'PullRequestEvent':
                return renderPullRequestEventAction(event);
            case 'IssueCommentEvent':
                return renderIssueCommentEventAction(event);
            case 'CreateEvent':
                return renderCreateEventAction(event);
            case 'IssuesEvent':
                return renderIssuesEventAction(event);
            case 'WatchEvent':
                return renderWatchEvent(event);
            case 'PushEvent':
                return renderPushEvent(event);
            default:
                return event.type;
        }
    }
    const renderEvents = () => {
        const renderedEvents = events.map(event => {
            const eventAction = renderEventAction(event);
            if (!eventAction) {
                return null;
            }
            const timeAgo = moment(event.created_at).fromNow();
            return <p key={event.created_at}>
                <span className={'time-ago'}>{timeAgo}</span> | {renderAuthorLink()} {eventAction}
            </p>
        });
        return renderedEvents.filter(event => event).slice(0, 10);
    }
    return <div id={'github-activity'}>
        {renderAuthor()}
        <hr/>
        {renderEvents()}
    </div>
}

GitHubActivity.propTypes = {
    username: PropTypes.string.isRequired
};
export default GitHubActivity;
