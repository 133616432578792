import './Body.scss';
import Content from "../Content/Content";
import LayoutLimiter from "../LayoutLimiter/LayoutLimiter";
import PropTypes from 'prop-types';

function Body(props) {
    return <div id={'body'}>
        <LayoutLimiter>
            <div className="bx--grid">
                <div className="bx--row">
                    <div className="bx--col-lg-12"><Content>{props.content}</Content></div>
                    <div className="bx--col-lg-4">{props.sidebar}</div>
                </div>
            </div>
        </LayoutLimiter>
    </div>;
}

Body.propTypes = {
    content: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired,
};
export default Body;
