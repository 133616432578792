import './Header.scss';
import LayoutLimiter from "../LayoutLimiter/LayoutLimiter";
import {NavLink} from "react-router-dom";

function Header() {
    return <div id={'header'}>
        <LayoutLimiter>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/project/shopping-list">Shopping list</NavLink>
            <NavLink to="/project/eve-trading">EVE Trading</NavLink>
        </LayoutLimiter>
    </div>;
}

export default Header;
