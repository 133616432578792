import './style/base.scss';
import Header from "./components/Header/Header";
import {Routes, Route} from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import Sidebar from "./components/Sidebar/Sidebar";
import Body from "./components/Body/Body";
import EvePage from "./pages/Eve/EvePage";
import ShoppingListPage from "./pages/ShoppingList/ShoppingListPage";
import LocationScrollToTop from "./LocationScrollToTop";
import ReactGA from "react-ga4";

ReactGA.initialize("G-PLDPHD2NM7");

function App() {
    const sidebar = <Sidebar/>
    return <>
        <LocationScrollToTop/>
        <Header/>
        <Routes>
            <Route path="/" element={<Body content={<HomePage/>} sidebar={sidebar}/>}/>
            <Route path="/project/shopping-list" element={<Body content={<ShoppingListPage/>} sidebar={sidebar}/>}/>
            <Route path="/project/eve-trading" element={<Body content={<EvePage/>} sidebar={sidebar}/>}/>
        </Routes>
    </>;
}

export default App;
