import StackoverflowProfileLink from "../../components/StackoverflowProfileLink/StackoverflowProfileLink";
import {Link} from "react-router-dom";
import ExternalLink from "../../components/ExternalLink/ExternalLink";
import moment from "moment";

export default function HomePage() {
    const freeTimeStartDate = new Date('2002-01-01');
    const professionalStartDate = new Date('2009-09-01');
    const totalExperienceYears = moment(new Date()).diff(freeTimeStartDate, 'years')
    const professionalExperienceYears = moment(new Date()).diff(professionalStartDate, 'years')
    const linkedInLink = <a target='_blank' rel="noreferrer"
                            href="http://www.linkedin.com/pub/fabian-picone/88/47b/2a3">
        LinkedIn
    </a>
    return <>
        <p>
            Hi, my name is Fabian.<br/>
            I have {professionalExperienceYears} years of professional experience in web development, having
            started {totalExperienceYears} years ago in my free time.
        </p>
        <p>
            I have worked with various employers, including agencies, product developers, and a top-ranked university.
            Additionally, I work on personal web projects in my free time. These experiences have provided me with a
            broad range of expertise.
        </p>
        <p>
            In my current work, I use PHP, Symfony, and Next.js. I prioritize OOP, SOLID principles, Clean Code, and
            TDD.
        </p>

        <h3>Profiles</h3>
        <ul>
            <li>
                <ExternalLink href={'https://stackoverflow.com/users/4840661/fabpico'}>
                    Stack overflow
                </ExternalLink>
                <br/>
                <StackoverflowProfileLink/>
            </li>
            <li>
                <a target='_blank' rel="noreferrer" href="https://github.com/fabpico">
                    GitHub
                </a>
            </li>
            <li>{linkedInLink}</li>
        </ul>

        <h3>Free time projects</h3>
        <ul>
            <li><Link to="/project/shopping-list">Shopping list</Link></li>
            <li><Link to="/project/eve-trading">EVE Trading</Link></li>
            <li>
                <ExternalLink href="https://github.com/fabpico/youtube-playlist-to-mp3">
                    Youtube playlist to mp3
                </ExternalLink>
            </li>
            <li>
                <ExternalLink href="https://github.com/fabpico/spotify-playlists-backupper">
                    Spotify playlists backupper
                </ExternalLink>
            </li>
            <li>... and more</li>
        </ul>
    </>
}