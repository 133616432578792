import ImageGallery from 'react-image-gallery';
import marketImageSource from './market.png';
import balanceImageSource from './balance.png';
import Changelog from "./Changelog";
import "react-image-gallery/styles/scss/image-gallery.scss";
import './style.scss';

export default function EvePage() {
    const images = [
        {original: balanceImageSource, thumbnail: balanceImageSource},
        {original: marketImageSource, thumbnail: marketImageSource}
    ];
    return <div id={'projects'}>
        <h2>Project: EVE Trading</h2>

        <div className={'gallery'}>
            <ImageGallery items={images}
                          showFullscreenButton={false}
                          showPlayButton={false}
            />
        </div>
        <div className="bx--grid">
            <div className="bx--row">
                <div className="bx--col-lg-9">
                    <h3>The motivation</h3>
                    <p>In <a href="https://en.wikipedia.org/wiki/Eve_Online">EVE Online</a>, which has a player-driven
                        economy, I wanted to earn as much
                        virtual money as possible, for being able to pay the monthly subscription fee
                        without having to spend real money.
                        Playing normally was not profitable enough and I heard of
                        making much virtual money with trading. As I started trading
                        in 2017, a month of subscription could be bought with 1 billion virtual money.</p>

                    <h3>The problem</h3>
                    <p>How can I identify profitable products for trading? Initially, I found instructions on
                        importing market orders from official EVE APIs into spreadsheets.
                        However, the APIs stopped working with spreadsheets around that time.
                        The alternative was to utilize existing 3rd-party market applications, which I found
                        inconvenient.</p>

                    <h3>The solution</h3>
                    <p>I decided to create my own application.<br/>
                        By using PHP to access the official EVE APIs, I was able to retrieve market orders and process
                        them as needed.<br/>
                        My goal was simple: I wanted to buy products at a low price and sell them for a higher price.
                        The challenge was to deal with the massive amount of data that needed to be loaded and
                        processed, but it was also the part that I found most enjoyable.</p>

                    <h3>Was it worth?</h3>
                    <p>The diagram shows that before I started to trade, I stayed around 0,3
                        billion virtual money. As I started to trade, everything changed. The more I traded, the more I
                        did investments that I could never think of before. Expensive items weren’t that expensive
                        anymore.
                        Point #1 on the diagram shows when I spent 1 billion virtual money to buy a month of
                        subscription. I did that monthly.
                        Point #2 shows a bad day where I bought products worth 0,3 billion virtual money, but I was
                        unable to sell them and convert them into profit because the carrier crumbled into cosmic
                        fragments.</p>

                    <p>It was truly enjoyable to bring this idea to life and see its success.</p>

                    <h3>The future</h3>
                    <p>I am thinking of making this project open source one day.</p>

                </div>
                <div className="bx--col-lg-1"></div>
                <div className="bx--col-lg-6">
                    <Changelog/>
                </div>
            </div>
        </div>
    </div>
}