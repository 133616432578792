import list from './screenshots/list.png'
import dishes from './screenshots/dishes.png'
import changeDish from './screenshots/change-dish.png'
import addProductToDish from './screenshots/add-product-to-dish.png'

export default function ShoppingListPage() {
    return <div id={'shopping-list'}>
        <h2>Project: Shopping list app</h2>
        <p>
            Using a shopping list app has become a common practice in today's digital age. However, if you find yourself
            repeatedly entering many ingredients for your dishes, it can become a bit repetitive over time.
            Additionally, existing apps often overwhelm users with increasing amounts of advertising.<br/>
            Sometimes you have to walk back and forth through the entire store because the entries aren't sorted.
        </p>
        <p>
            To solve these issues, I created my own app. For example it provides adding ingredients into the
            shopping list, by choosing a dish. All without receiving advertising or subscription requests.<br/>
            Furthermore, imagine having added multiple dishes with same ingredients into the shopping list. The app
            conveniently merges same items, with the total quantity summed up.
        </p>
        <p>
            <img src={list} height={300}/>&nbsp;
            <img src={dishes} height={300}/>&nbsp;
            <img src={changeDish} height={300}/>&nbsp;
            <img src={addProductToDish} height={300}/>
        </p>
        <p>
            My friends thought this solution was great and they wanted it too. So I decided to implement a
            functionality for having individual accounts and groups, allowing them to create their own dishes and
            shopping lists.
        </p>
        <p>
            At times, you may come across items on your shopping list that you're unsure about, especially if someone
            else added them.
            That's why I integrated a "what the heck is...?" feature, which conveniently adds a Google search link for
            every item in the shopping list.
        </p>
        <p>
            The app truly simplifies life and adds a touch of convenience to our shopping experiences.
        </p>
        <p><strong>Features</strong></p>
        <ul>
            <li>Manage shopping list items</li>
            <li>Manage dishes, with related ingredients as products, and their quantities</li>
            <li>Add dish ingredients to the shopping list</li>
            <li>Merge shopping list items, coming from different dishes</li>
            <li>Sort shopping list by compartments</li>
            <li>"What the heck is ..?" link for shopping list items</li>
            <li>Group based login</li>
            <li>And more!</li>
        </ul>
    </div>
}