import GitHubActivity from "../GitHubActivity/GitHubActivity";
import './Sidebar.scss';

function Sidebar() {
    return <div id={'sidebar'}>
        <p>GitHub activity</p>

        <GitHubActivity username={'fabpico'}/>
    </div>;
}

export default Sidebar;
