export default function Changelog() {
    return <>
        <h3>Changelog</h3>
        <h4>v1.1.1 - February 2022</h4>
        <p>Optimizations</p>
        <ul>
            <li>Provide Docker environment</li>
            <li>Optimize performance of processing data</li>
            <li>Optimize automation</li>
            <li>Optimize storage management</li>
            <li>Optimize code and tests</li>
            <li>Optimize logging</li>
        </ul>

        <hr/>

        <h4>v1.1 - May 2019</h4>
        <p>Bugfixes</p>
        <ul>
            <li>Replace discontinued <a
                href="https://eveonline-third-party-documentation.readthedocs.io/en/latest/crest/">EVE CREST
                API</a> with <a href="https://esi.evetech.net/">EVE Swagger Interface</a>
            </li>
        </ul>
        <p>Features</p>
        <ul>
            <li>Provide API for scheduled processing</li>
            <li>Filter out products with probably high concurrence</li>
            <li>Determine region-wide trade amount of products</li>
        </ul>
        <p>Optimizations</p>
        <ul>
            <li>Optimize memory usage for systems with only 1GB RAM (Raspberry Pi)</li>
        </ul>

        <hr/>

        <h4>v1.0 - April 2017</h4>
        <p>Features</p>
        <ul>
            <li>Determine profitable products to trade, by processing sell orders from main stations</li>
            <li>Track wallet balance</li>
        </ul>
    </>
}